<template>
  <div class="emb-card pa-4 admin-profile-wrap">
    <div class="mb-8">
      <h5>Settings</h5>
      <div class="d-flex flex-row align-center">
        <v-subheader>Stop selling hour (1 to 23 hour):</v-subheader>
        <div v-if="stop_hour != -1" class="number-width">
          <v-text-field
            v-model="stop_hour"
            single-line
            type="number"
            step="0.5"
            label=""
          />
        </div>
        <div>Hour</div>
      </div>
    </div>

    <div>
      <v-btn
        color="accent"
        slot="activator"
        @click="updateSettings()"
        :disabled="disable_update_btn"
      >
        <div>Update Settings</div>
      </v-btn>
    </div>
    <v-snackbar v-model="snackbar" color="red">
      Invalid hour, it must be between 1 and 23.
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
export default {
  data() {
    return {
      stop_hour: -1,
      general_info: null,
      snackbar: false,
      disable_update_btn: false,
    };
  },
  methods: {
    updateSettings() {
      this.disable_update_btn = true;
      const updateSettingsFunc = httpsCallable(
        getFunctions(),
        "updateAdminSettings"
      );
      updateSettingsFunc({ stop_purchase: this.stop_hour })
        .then((result) => {
          this.disable_update_btn = false;
          if (result.data.code == "OK") {
            this.$snotify.success("Settings updated.", {
              closeOnClick: false,
              pauseOnHover: false,
              timeout: 1000,
            });
          }
        })
        .catch((error) => {
          this.disable_update_btn = false;
          console.log("Error: ", error);
          this.snackbar = true;
          this.fetch_info();
        });
    },
    // customers_count() {
    //   const coll = collection(db, "cities");
    //   const snapshot = await getCountFromServer(coll);
    //   console.log("count: ", snapshot.data().count);
    // },
    fetch_info() {
      this.$store.dispatch("fetch_general_info").then((info) => {
        this.general_info = info;
        this.stop_hour = 23 - info.limits.stop_purchase_hour;
      });
    },
  },
  mounted() {
    this.fetch_info();
  },
};
</script>
<style>
.thumb-width {
  width: 70px !important ;
}
.account-text .v-text-field__details {
  display: none;
}
.number-width {
  width: 50px;
}
</style>
